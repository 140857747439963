import React from 'react';
import GooglePlay from './GooglePlayStore.png';
import AppleApp from './AppleAppStore.svg';
import Facebook from './facebookLogo.png';

import styled from 'styled-components';

const Content = styled.div`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
`;

const Header = styled.h1`
    font-size: ${window.screen.availWidth > 450 ? '5rem' : '3rem'};
    color: white;
    margin-top: 10px;
    margin-bottom: 0;
`;

const Description = styled.h2`
    font-size: ${window.screen.availWidth > 450 ? '2rem' : '1.5rem'};
    color: white;
    margin-top: 0;
`;

const Horizontal = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

const Button = styled.div`
    cursor: pointer;
`;

const Image = styled.img`
    height: ${window.screen.availWidth > 450 ? '65px' : '35px'};
    filter: ${({ disabled }) => (disabled ? 'blur(3px)' : 'none')};
`;

const SocialImage = styled.img`
    height: 35px;
    width: 35px;
`;

const Background = () => {
    const playStoreURL = 'https://play.google.com/store/apps/details?id=com.mlang1.shuttlebuddy';
    const appStoreURL = 'https://apps.apple.com/ca/app/shuttle-buddy/id6479181472';
    const facebookURL = 'https://www.facebook.com/shuttlebuddy';

    return (
        <div
            style={{
                backgroundImage: 'url("/Banner.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Content>
                <Header>Shuttling Sucks</Header>
                <Description>Shuttle Buddy will do it for you</Description>
            </Content>

            <Horizontal>
                <Button onClick={()=>{window.open(playStoreURL, '_blank').focus()}}>
                    <Image src={GooglePlay} alt="Google Play Store" />
                </Button>
                <Button onClick={()=>{window.open(appStoreURL, '_blank').focus()}}>
                    <Image src={AppleApp} alt="Apple App Store"/>
                </Button>
            </Horizontal>

            <Horizontal style={{marginTop: '50px'}}>
                <Button>
                    <SocialImage onClick={()=>{window.open(facebookURL, '_blank').focus()}} src={Facebook} alt="Facebook" />
                </Button>
            </Horizontal>
                
        </div>
    );
};

export default Background;
